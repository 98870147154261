import { LINK_APP_STORE, LINK_GOOGLE_PLAY } from '../Utils/Constants';
import Images from '../Utils/Images';
import styles from './TopBar.module.scss';


function sc2bottom() {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: 'smooth'
  });
}

type MobileOS = 'Android' | 'iOS' | 'Other';

function detectMobileOS(): MobileOS {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return 'iOS';
  }

  return 'Other';
}

function clickDownload() {
  const os: MobileOS = detectMobileOS();
  switch (os) {
    case 'Android':
      window.open(LINK_GOOGLE_PLAY, '_blank');
      break;
    case 'iOS':
      window.open(LINK_APP_STORE, '_blank');
      break;
    default:
      sc2bottom();
      break;
  }
}

export default function TopBar() {
  return (
    <div className={styles.TopBar}>
      <div className={styles.left}>
        <img
          src={Images.app_logo_128}
          alt="logo"
        />
        Political Party Frenzy
      </div>
      <div
        className={styles.right}
        onClick={clickDownload}
      >
        Download App
      </div>
    </div>
  )
}