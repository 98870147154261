const RES_PREFIX = '/imgs';
const Images = {
  app_logo_128: `${RES_PREFIX}/app_logo_128.jpg`,
  web_comp: `${RES_PREFIX}/web_comp.png`,

  sc1: `${RES_PREFIX}/sc1.png`,
  sc2: `${RES_PREFIX}/sc2.png`,
  sc3: `${RES_PREFIX}/sc3.png`,


  superstar: `${RES_PREFIX}/superstar.png`,

  btn_applestore: `${RES_PREFIX}/btn_appstore.svg`,
  btn_googleplay: `${RES_PREFIX}/btn_googleplay.svg`,
}

export default Images;