import TopBar from '../Components/TopBar';
import { LINK_APP_STORE, LINK_GOOGLE_PLAY } from '../Utils/Constants';
import Images from '../Utils/Images';
import styles from './Home.module.scss';

export default function Home() {
  return (
    <div className={styles.Home}>
      <TopBar />
      <div className={styles.container}>
        <img
          className={styles.banner}
          alt="Political Party Frenzy"
          src={Images.web_comp}
        />
      </div>
      <div className={[styles.container, styles.msg_container].join(' ')}>
        <h1>Red, White, and YOU!</h1>
        <p className={styles.text}>
          Play the ultimate casual puzzler with a patriotic twist. Blending strategy, luck, and humor, Political Party Frenzy offers a lighthearted peek into the wild world of American politics. Tackle hundreds of hilarious scandals, compete on leaderboards, and gain millions of followers as you lead your party to the top!
        </p>
      </div>
      <div className={styles.container}>
        <div className={styles.imgRow}>
          <img
            className={styles.img_sc}
            alt="Political Party Frenzy"
            src={Images.sc1}
          />
          <img
            className={styles.img_sc}
            alt="Political Party Frenzy"
            src={Images.sc2}
          />
          <img
            className={styles.img_sc}
            alt="Political Party Frenzy"
            src={Images.sc3}
          />
        </div>
      </div>
      <div className={styles.container}>
        <img
          className={styles.superstar}
          alt="Superstar"
          src={Images.superstar}
        />
      </div>
      <div className={[styles.container, styles.msg_container].join(' ')}>
        <h1>Political Party Frenzy</h1>
        <p className={styles.text}>
          Political Party frenzy is a playful dive into American politics. It combines competitive and casual gameplay, allowing players to influence a virtual representation of America's political landscape. As players climb the leaderboards, each win becomes a powerful statement. It's a fresh experience wrapped in the charm and wit of Americana.
        </p>
      </div>
      <div className={styles.container}>
        <DownloadApp />
      </div>
    </div>
  )
}

function DownloadApp() {
  return (
    <div className={styles.download_btns}>
      <a href={LINK_APP_STORE} target='_blank' rel="noreferrer" >
        <div className={styles.btn_download}>
          <img
            src={Images.btn_applestore}
            alt="App Store"
          />
        </div>
      </a>
      <a href={LINK_GOOGLE_PLAY} target='_blank' rel="noreferrer" >
        <div className={styles.btn_download}>
          <img
            src={Images.btn_googleplay}
            alt="Google Play"
          />
        </div>
      </a>
    </div>
  )
}